import flatpickr from 'flatpickr';
import {German} from 'flatpickr/dist/l10n/de.js';
import * as moment from 'moment';

import {qrcodegen} from './qrcodegen';

let code: qrcodegen.QrCode | null = null;
let startPicker: flatpickr.Instance | null = null;
let endPicker: flatpickr.Instance | null = null;

function setup() {
	// Get Elements
	const form = document.getElementById('form') as HTMLFormElement;
	const textElement = document.getElementById('text') as HTMLTextAreaElement;
	const svgButton = document.getElementById(
		'download-svg'
	) as HTMLButtonElement;
	const pngButton = document.getElementById(
		'download-png'
	) as HTMLButtonElement;
	const typeElement = document.getElementById('type') as HTMLSelectElement;

	// Setup Handlers
	form.onchange = () => {
		update();
	};
	form.oninput = () => {
		update();
	};

	textElement.oninput = () => {
		update();
	};

	typeElement.oninput = () => {
		updateType();
	};

	svgButton.onclick = () => {
		if (code === null) {
			return;
		}

		const svg = code.toSvgString(0);
		const file = new Blob([svg], {type: 'image/svg+xml'});

		const a = document.createElement('a');
		a.href = URL.createObjectURL(file);
		a.download = 'qrcode.svg';
		a.click();
		URL.revokeObjectURL(a.href);
	};

	pngButton.onclick = () => {
		const canvasElement = document.getElementById(
			'canvas'
		) as HTMLCanvasElement;
		const image = canvasElement.toDataURL('image/png');

		const a = document.createElement('a');
		a.href = image;
		a.download = 'qrcode.png';
		a.click();
		URL.revokeObjectURL(image);
	};

	// Setup Date Picker
	const eventStartElement = document.getElementById(
		'event-start'
	) as HTMLInputElement;
	const eventEndElement = document.getElementById(
		'event-end'
	) as HTMLInputElement;
	flatpickr.localize(German);
	startPicker = flatpickr(eventStartElement, {
		enableTime: true,
		time_24hr: true,
		onChange: () => {
			update();
		},
	});
	endPicker = flatpickr(eventEndElement, {
		enableTime: true,
		time_24hr: true,
		onChange: () => {
			update();
		},
	});

	update();
	updateType();
}

function update() {
	// Get Elements
	const textElement = document.getElementById('text') as HTMLTextAreaElement;
	const eccElement = document.getElementById('ecc') as HTMLSelectElement;
	const canvasElement = document.getElementById(
		'canvas'
	) as HTMLCanvasElement;
	const scaleElement = document.getElementById('scale') as HTMLInputElement;
	const informationElement = document.getElementById(
		'information'
	) as HTMLParagraphElement;
	const typeElement = document.getElementById('type') as HTMLSelectElement;

	// Update Form
	const wifiTypeElement = document.getElementById(
		'wifi-auth'
	) as HTMLSelectElement;
	const wifiPassElement = document.getElementById(
		'wifi-pass-container'
	) as HTMLDivElement;
	wifiPassElement.style.display =
		wifiTypeElement.value !== 'nopass' ? 'block' : 'none';

	const eventDayElement = document.getElementById(
		'event-day'
	) as HTMLSelectElement;
	startPicker?.set('enableTime', eventDayElement.value === 'false');
	startPicker?.set(
		'dateFormat',
		eventDayElement.value === 'false' ? 'Y-m-d H:i' : 'Y-m-d'
	);
	endPicker?.set('enableTime', eventDayElement.value === 'false');
	endPicker?.set(
		'dateFormat',
		eventDayElement.value === 'false' ? 'Y-m-d H:i' : 'Y-m-d'
	);

	// Generate QRCode
	let text = '';
	if (typeElement.value.startsWith('text')) {
		text = textElement.value;
	} else if (typeElement.value === 'contact') {
		text = contactText();
	} else if (typeElement.value === 'wifi') {
		text = wifiText();
	} else if (typeElement.value === 'sepa') {
		text = sepaText();
	} else if (typeElement.value === 'event') {
		text = eventText();
	}

	code = qrcodegen.QrCode.encodeText(
		text,
		qrcodegen.QrCode.Ecc[eccElement.value]
	);
	code?.drawCanvas(parseInt(scaleElement.value, 10), 0, canvasElement);

	// Display Information
	informationElement.innerHTML = `Version: ${code.version}<br />Größe: ${canvasElement.width}px`;
}

function updateType() {
	// Get Elements
	const textElement = document.getElementById('text') as HTMLTextAreaElement;
	const typeElement = document.getElementById('type') as HTMLSelectElement;
	const contactForm = document.getElementById(
		'contact-form'
	) as HTMLDivElement;
	const wifiForm = document.getElementById('wifi-form') as HTMLDivElement;
	const sepaForm = document.getElementById('sepa-form') as HTMLDivElement;
	const eventForm = document.getElementById('event-form') as HTMLDivElement;

	if (typeElement.value.startsWith('text')) {
		const placeholderMap: any = {
			text: 'Text',
			'text-url': 'https://elephants5.com',
			'text-email': 'mailto:office@elephants5.com',
			'text-telephone': 'tel:+43722921131',
			'text-sms': 'sms:+43722921131',
			'text-geo': 'geo:48.22179266749908,14.236953512113445,0',
		};
		textElement.placeholder = placeholderMap[typeElement.value];

		if (
			textElement.value === '' ||
			Object.values(placeholderMap).includes(textElement.value)
		) {
			textElement.value = placeholderMap[typeElement.value];
		}
	}

	textElement.style.display = typeElement.value.startsWith('text')
		? 'block'
		: 'none';
	contactForm.style.display =
		typeElement.value === 'contact' ? 'block' : 'none';
	wifiForm.style.display = typeElement.value === 'wifi' ? 'block' : 'none';
	sepaForm.style.display = typeElement.value === 'sepa' ? 'block' : 'none';
	eventForm.style.display = typeElement.value === 'event' ? 'block' : 'none';
}

function contactText() {
	const nameElement = document.getElementById(
		'contact-name'
	) as HTMLInputElement;
	const companyElement = document.getElementById(
		'contact-company'
	) as HTMLInputElement;
	const phoneElement = document.getElementById(
		'contact-telephone'
	) as HTMLInputElement;
	const mobileElement = document.getElementById(
		'contact-mobile'
	) as HTMLInputElement;
	const addressElement = document.getElementById(
		'contact-address'
	) as HTMLInputElement;
	const emailElement = document.getElementById(
		'contact-email'
	) as HTMLInputElement;
	const urlElement = document.getElementById(
		'contact-url'
	) as HTMLInputElement;

	let text = 'BEGIN:VCARD\nVERSION:3.0\n';

	if (nameElement.value !== '') {
		const parts = nameElement.value.split(' ').reverse();
		text += `N:${parts.join(';')}\n`;
		text += `FN:${nameElement.value}\n`;
	}

	if (companyElement.value !== '') {
		text += `ORG:${companyElement.value}\n`;
	}

	if (phoneElement.value !== '') {
		text += `TEL;TYPE=work:${phoneElement.value}\n`;
	}

	if (mobileElement.value !== '') {
		text += `TEL;TYPE=cell:${mobileElement.value}\n`;
	}

	if (addressElement.value !== '') {
		const parts = addressElement.value.split(', ');
		text += `ADR:${parts.join(';')}\n`;
	}

	if (emailElement.value !== '') {
		text += `EMAIL:${emailElement.value}\n`;
	}

	if (urlElement.value !== '') {
		text += `URL:${urlElement.value}\n`;
	}

	text += 'END:VCARD';
	console.log(text);
	return text;
}

function wifiText() {
	const typeElement = document.getElementById(
		'wifi-auth'
	) as HTMLSelectElement;
	const nameElement = document.getElementById(
		'wifi-name'
	) as HTMLInputElement;
	const hiddenElement = document.getElementById(
		'wifi-hidden'
	) as HTMLSelectElement;
	const passElement = document.getElementById(
		'wifi-pass'
	) as HTMLInputElement;

	let text = 'WIFI:';

	text += `T:${typeElement.value};`;
	text += `S:${nameElement.value};`;

	if (typeElement.value !== 'nopass') {
		text += `P:${passElement.value};`;
	}

	if (hiddenElement.value === 'true') {
		text += 'H:true;';
	} else {
		text += 'H:;';
	}

	return text + ';';
}

function sepaText() {
	const nameElement = document.getElementById(
		'sepa-name'
	) as HTMLInputElement;
	const ibanElement = document.getElementById(
		'sepa-iban'
	) as HTMLInputElement;
	const amountElement = document.getElementById(
		'sepa-amount'
	) as HTMLInputElement;
	const infoElement = document.getElementById(
		'sepa-information'
	) as HTMLInputElement;

	const amount = parseFloat(amountElement.value);

	let text = 'BCD\n';
	text += '002\n';
	text += '1\n';
	text += 'SCT\n';
	text += '\n';
	text += `${nameElement.value}\n`;
	text += `${ibanElement.value}\n`;
	text += `EUR${amount.toFixed(2)}\n`;
	text += '\n';
	text += '\n';
	text += `${infoElement.value}\n`;
	text += '\n';

	return text;
}

function eventText() {
	const nameElement = document.getElementById(
		'event-name'
	) as HTMLInputElement;
	const eventDayElement = document.getElementById(
		'event-day'
	) as HTMLSelectElement;

	let text = 'BEGIN:VEVENT\n';
	text += `SUMMARY:${nameElement.value}\n`;

	if (startPicker && endPicker) {
		if (eventDayElement.value === 'true') {
			const dateFormat = 'YYYYMMDD';
			const startDate = moment(startPicker.selectedDates[0]).format(
				dateFormat
			);
			const endDate = moment(endPicker.selectedDates[0]).format(
				dateFormat
			);
			text += `DTSTART;VALUE=DATE:${startDate}\n`;
			text += `DTEND;VALUE=DATE:${endDate}\n`;
		} else {
			const dateFormat = 'YYYYMMDD\\THHmmss';
			const startDate = moment(startPicker.selectedDates[0]).format(
				dateFormat
			);
			const endDate = moment(endPicker.selectedDates[0]).format(
				dateFormat
			);
			text += `DTSTART:${startDate}\n`;
			text += `DTEND:${endDate}\n`;
		}
	}
	text += 'END:VEVENT';

	return text;
}

setup();
